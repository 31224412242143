import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Footer from "../common/footer";
import { Helmet } from 'react-helmet-async';

export default function PrivacyPolicy() {

    const navigate = useNavigate();

    const policy = "Thank you for choosing our book reading website. We understand the importance of protecting your personal information and respecting your privacy. In this privacy policy, we will explain how we collect, use, and protect your information when you use our website and Facebook authentication.\n" +
        "Information We Collect: When you use our website and sign up using Facebook authentication, we may collect the following information:\n" +
        "•\tYour name, email address, and other basic profile information provided by Facebook.\n" +
        "•\tInformation about the books you have read or are interested in.\n" +
        "Information Sharing: We do not sell or share your personal information with third parties for marketing purposes. However, we may share your information with service providers who help us operate our website and provide our services. We may also share your information if required by law or to protect our rights and interests.\n" +
        "Data Security: We take data security seriously and have implemented appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure.\n" +
        "Cookies: We use cookies and other similar technologies to personalize your experience on our website and to analyze usage patterns. You can control cookies through your browser settings.\n" +
        "Changes to This Privacy Policy: We may update this privacy policy from time to time. If we make any material changes, we will notify you by email or by posting a notice on our website.\n" +
        "Contact Us: If you have any questions or concerns about this privacy policy or how we use your information, please contact us at [gangashinienovels@gmail.com]."

    return (
        <div className="container mx-auto my-4 w-11/12 md:w-1/2 p-8 border border-gray-400">

            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>

            <button className="text-red-500 hover:text-red-800" onClick={() => { navigate(-1) }}>
                <ArrowLeftCircleIcon className="h-10 w-10" />
            </button>
            <h1 className="text-bold text-2xl md:text-3xl text-red-500 mb-8">Privacy Policy</h1>


            <p className="whitespace-pre-wrap">{policy}</p>

            <Footer />
        </div>
    )
}