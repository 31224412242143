import { collection, addDoc, query, where, getDoc, getDocs, doc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase-config';


// const novels = collection(this.db, 'novels');

class NovelsService {

    async addNovel(novel) {
        try {
            const docRef = await addDoc(collection(db, "novels"), novel);
            console.log("Document written with ID: ", docRef.id);
            return docRef.id;
        } catch (e) {
            console.error("Error adding document: ", e);
            return null;
        }
    }

    async getNovels() {
        const novels = []
        const querySnapshot = await getDocs(collection(db, "novels"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            novels.push({
                id: doc.id,
                title: doc.data().title,
                author: doc.data().author,
                description: doc.data().description,
                price: doc.data().price,
                views: doc.data().views,
                timestamp: doc.data().timestamp
            });
        });
        return novels;
    }

    async getNovel(id) {
        const novelsRef = collection(db, "novels");
        const q = query(novelsRef, where("id", "==", id));
        const novelDoc = await getDoc(doc(db, "novels", id));

        if(novelDoc.exists()){
            return {
                id: novelDoc.id,
                title: novelDoc.data().title,
                author: novelDoc.data().author,
                description: novelDoc.data().description,
                price: novelDoc.data().price,
                views: novelDoc.data().views,
                timestamp: new Date(novelDoc.data().timestamp).toLocaleString()
            };
        }
        else{
            return null;
        }
    }

    async searchNovels(key) {
        const novels = []
        const querySnapshot = await getDocs(collection(db, "novels"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if (doc.data().title.includes(key)){
                novels.push({
                    id: doc.id,
                    title: doc.data().title,
                    author: doc.data().author,
                    description: doc.data().description,
                    price: doc.data().price,
                    views: doc.data().views,
                    timestamp: new Date(doc.data().timestamp).toLocaleString()
                });
            }
        });
        return novels;
    }

    async addBookMark(userId, novelId, pageNumber) {
        const bookmark = {
            pageNumber: pageNumber
        }

        try {
            console.log(userId + "  " + novelId );
            const bookmarkRef = doc(db, "bookmarks", userId+novelId);
            const bmDoc = await getDoc(bookmarkRef);

            if(bmDoc.exists()){
                await updateDoc(bookmarkRef, bookmark);
            }
            else{
                await setDoc(bookmarkRef, bookmark);
            }
            return true;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }

    async getBookMark(userId, novelId) {
        const bookmarkRef = doc(db, "bookmarks", userId+novelId);
        const bmDoc = await getDoc(bookmarkRef);

        if(bmDoc.exists()){
            return bmDoc.data().pageNumber;
        }
        return 1;
    }

    async getAuthorizedNovels(userId) {

        const novels = []
        const q = query(collection(db, "purchases"), where("userId", "==", userId));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data().novelId);

            novels.push(doc.data().novelId);

        });

        return novels;
    }

    async authorizeUser(userId, novelId){
        const recordRef = doc(db, "purchases", userId + novelId);
        const recordDoc = await getDoc(recordRef);

        if(recordDoc.exists()){
            return true;
        }
        return false;
    }

}

export default new NovelsService();