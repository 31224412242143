import { LockClosedIcon } from '@heroicons/react/20/solid'
import { NavLink, useNavigate } from 'react-router-dom'
import { ArrowLeftCircleIcon, HomeIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Helmet } from 'react-helmet-async';

export default function Login() {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => { }, [error]);

    function handleSignIn() {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;

                navigate("/home");
                // ...
            })
            .catch((error) => {
                setError("Invalid email or password. Please check your credentials and try again!");
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }

    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <Helmet>
                    <title>Sign In</title>
                </Helmet>
                <div className="w-full max-w-md space-y-8">
                    <div className="flex justify-center">
                        <button onClick={() => navigate(-1)}>
                            <ArrowLeftCircleIcon className="w-10 h-10 mx-12 text-red-400 hover:text-red-700" />
                        </button>
                        <button onClick={() => navigate("/")}>
                            <HomeIcon className="w-10 h-10 mx-12 text-red-400 hover:text-red-700" />
                        </button>
                    </div>
                    <div>
                        <img
                            className="mx-auto w-1/2"
                            src={require("../images/logo.png")}
                            alt="GanagaShinie Novels"
                        />
                        <h2 className="mt-6 text-center text-3xl tracking-tight text-gray-900">
                            Sign in
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            <NavLink to="/register" className="font-medium text-red-500 hover:text-red-700">
                                Don't have an account? Sign up now!
                            </NavLink>
                        </p>
                    </div>

                    {error.length > 0 ?
                        <div className="flex justify-center bg-orange-300 rounded-lg px-4 py-2 my-4">
                            <label className="text-white text-center">{error}</label>
                        </div> : ""
                    }

                    <div className="mt-8 space-y-6">
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">

                            <div className="text-sm">
                                <button onClick={() => navigate('/reset-password')} className="font-medium text-red-500 hover:text-red-700">
                                    Forgot your password?
                                </button>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                                onClick={handleSignIn}
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon className="h-5 w-5 text-red-300 group-hover:text-red-400" aria-hidden="true" />
                                </span>
                                Sign in
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}