import { Base64 } from "js-base64";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DailyNovelService from "../services/daily-novel-service";
import StorageService from "../services/storage-service";
import { ArrowLeftCircleIcon } from "@heroicons/react/20/solid";
import { FacebookShareButton, FacebookMessengerShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, FacebookMessengerIcon, WhatsappIcon } from "react-share";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async";

export default function DailyNovel() {

    const navigate = useNavigate();

    const params = useParams();

    const [episode, setEpisode] = useState(null);
    const [image, setImage] = useState(null);
    const [views, setViews] = useState(null);

    const currentURL = "https://gangashinienovels.lk" + window.location.pathname;

    useEffect(() => {
        DailyNovelService.getEpisode(params.episodeId).then((epi) => {
            setEpisode(epi);

            StorageService.getDailyNovelCover(epi.title).then((url) => {
                setImage(url);
            });
            
            // DailyNovelService.addView(epi.episode);
            DailyNovelService.getViews(epi.episode).then((count) => {
                setViews(count);
            });
        });


    }, [])

    useEffect(() => {
    }, [image]);
    useEffect(() => {
    }, [episode]);
    useEffect(() => {
    }, [views]);

    return (
        <div className="container mx-auto w-11/12 md:w-1/2">
            {episode ?
                (<>
                    <Helmet>
                        <title>{episode.title + " - " + episode.episode + " වන කොටස"}</title>
                    </Helmet>
                    <div className="grid grid-cols-6">
                        <div className="col-span-1">
                            <button
                                className="text-xl font-semibold leading-6 text-red-900 hover:text-red-400 focus:text-red-300"
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                <ArrowLeftCircleIcon className="h-10 w-10" />
                            </button>
                        </div>
                        <div className="col-span-4">
                            <h1 className="text-xl text-red-700">{episode.title} : {episode.episode} වන කොටස</h1>
                            <h2 className="text-md text-gray-500">{episode.timestamp}</h2>
                        </div>
                        <div className="flex my-auto">
                            { true ? <><EyeIcon className="w-5 text-gray-300 mr-4 my-auto" /> <h2
                                className="text-gray-300 my-auto">{views}</h2></> : ""}
                        </div>
                    </div>

                    {/*<img src={require("../images/daily-novel/dailyNovel.png")} alt={episode.title}*/}
                    {/*    className="my-8 rounded-lg" />*/}

                    { image ?
                        <img src={image} alt={episode.title} className="my-8 rounded-lg"/> : ""
                    }

                    <p className="whitespace-pre-wrap">{Base64.decode(episode.content)}</p>
                </>) : <h1>Loading..</h1>
            }

            <div className="container mx-auto mt-8">
                <h2 className="text-red-500 text-xl text-center">Share</h2>
                <div className="flex justify-center">
                    <FacebookShareButton url={currentURL} hashtag="#gangashinienovels" quote="ප්‍රේම ගාථා II">
                        <FacebookIcon className="w-8 hover:w-10 mx-4" round="true" />
                    </FacebookShareButton>
                    <FacebookMessengerShareButton url={currentURL}>
                        <FacebookMessengerIcon className="w-8 hover:w-10 mx-4" round="true" />
                    </FacebookMessengerShareButton>
                    <WhatsappShareButton url={currentURL}>
                        <WhatsappIcon className="w-8 hover:w-10 mx-4" round="true" />
                    </WhatsappShareButton>
                </div>

            </div>

        </div>
    );
}