import {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {Base64} from "js-base64";
import DailyNovelService from "../services/daily-novel-service";
import StorageService from "../services/storage-service";


export default function EpisodeRow(props){

    const navigate = useNavigate();

    const [image, setImage] = useState(null);

    useEffect(() => {
        StorageService.getDailyNovelCover(props.episode.title).then((img) => {
            setImage(img);
        })
    },[])

    useEffect(() => {}, [image])

    return(
        <div className="border-solid border-2 border-red-150 hover:border-red-300 rounded-md my-2 p-2 max-h-36">
            <button onClick={() => {
                DailyNovelService.addView(props.episode.episode);
                navigate("/daily-novel/" + props.episode.id);
            }}>

                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-2 xl:col-span-1 mx-auto">
                         {/*<img src={require("../images/daily-novel/dailyNovel.png")} className="w-48 rounded-md my-auto"/>*/}
                        { image ?
                            <img src={image} alt={props.episode.title} className="w-48 rounded-md my-auto"/> : ""
                        }
                    </div>
                    <div className="col-span-4 xl:col-span-5 text-left px-4 ">
                        <div className="flex">
                            <h3 className="text-bold text-red-800 text-sm sm:text-lg mr-6">{props.episode.title} : {props.episode.episode} </h3>
                            <h3 className="text-gray-500 text-xs sm:text-sm">{props.episode.timestamp}</h3>
                        </div>
                        {/*<p className="text-xs sm:text-sm line-clamp-3">{atob(props.episode.content)}</p>*/}
                        <p className="text-xs sm:text-sm line-clamp-2 md:line-clamp-3">{Base64.decode(props.episode.content)}</p>
                    </div>
                </div>
            </button>
        </div>
    );
}