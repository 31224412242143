import { UserCircleIcon } from '@heroicons/react/20/solid';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import UserService from "../services/user-service";
import { ArrowLeftCircleIcon, HomeIcon } from "@heroicons/react/24/solid";
import { Helmet } from 'react-helmet-async';

export default function Register() {

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => { }, [error]);

    function handleSubmission() {
        if (firstName.length === 0) {
            setError("First name is required");
        }
        else if (lastName.length === 0) {
            setError("Last name is required");
        }
        else if (telephone.length === 0) {
            setError("Contact Number is required");
        }
        else if (email.length === 0) {
            setError("Email is required");
        }
        else if (password.length === 0) {
            setError("Password is required");
        }
        else if (confirmPassword.length === 0) {
            setError("Confirm Password is required");
        }
        else if (password != confirmPassword) {
            setError("Two passwords you entered are not matching. Please try again.")
        }
        else {
            setError("");
            const auth = getAuth();
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;

                    let userDoc = {
                        firstName: firstName,
                        lastName: lastName,
                        telephone: telephone,
                        email: email
                    }
                    UserService.addUser(userDoc).then(() => {

                        // sendEmailVerification(user).then(() => {
                        //     navigate("/verify-email");
                        // });

                        navigate("/login");

                    });


                    navigate("/login");
                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;

                    setError(error.message);
                    // ..
                });



        }
    }

    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <Helmet>
                    <title>Sign Up</title>
                </Helmet>
                <div className="w-full max-w-md space-y-8">
                    <div className="flex justify-center">
                        <button onClick={() => navigate(-1)}>
                            <ArrowLeftCircleIcon className="w-10 h-10 mx-12 text-red-400 hover:text-red-700" />
                        </button>
                        <button onClick={() => navigate("/")}>
                            <HomeIcon className="w-10 h-10 mx-12 text-red-400 hover:text-red-700" />
                        </button>
                    </div>
                    <div>
                        <img
                            className="mx-auto h-24 w-auto"
                            src={require("../images/logo.png")}
                            alt="Ganga Shinie Novels"
                        />
                        <h2 className="mt-6 text-center text-3xl tracking-tight text-gray-900">
                            Sign up
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            <NavLink to="/login" className="font-medium text-red-500 hover:text-red-700">
                                Already have an account? Sign in!
                            </NavLink>
                        </p>
                    </div>

                    {error.length > 0 ?
                        <div className="flex justify-center bg-orange-300 rounded-lg px-4 py-2 my-4">
                            <label className="text-white text-center">{error}</label>
                        </div> : ""
                    }
                    <div className="mt-8 space-y-6">
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="first-name" className="sr-only">
                                    First name
                                </label>
                                <input
                                    id="first-name"
                                    name="first-name"
                                    type="text"
                                    autoComplete="first-name"
                                    required
                                    minLength={2}
                                    maxLength={15}
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={(e) => { setFirstName(e.target.value) }}
                                />
                            </div>
                            <div>
                                <label htmlFor="last-name" className="sr-only">
                                    Last name
                                </label>
                                <input
                                    id="last-name"
                                    name="last-name"
                                    type="text"
                                    autoComplete="last-name"
                                    required
                                    minLength={2}
                                    maxLength={15}
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={(e) => { setLastName(e.target.value) }}
                                />
                            </div>
                            <div>
                                <label htmlFor="telephone" className="sr-only">
                                    Telephone
                                </label>
                                <input
                                    id="telephone"
                                    name="telephone"
                                    type="tel"
                                    autoComplete="telephone"
                                    required
                                    minLength={10}
                                    maxLength={20}
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Telephone"
                                    value={telephone}
                                    onChange={(e) => { setTelephone(e.target.value) }}
                                />
                            </div>
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    minLength={2}
                                    maxLength={75}
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    minLength={8}
                                    maxLength={20}
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                            </div>
                            <div>
                                <label htmlFor="confirm-password" className="sr-only">
                                    Confirm password
                                </label>
                                <input
                                    id="confirm-password"
                                    name="confirm-password"
                                    type="password"
                                    autoComplete="confirm-password"
                                    required
                                    minLength={8}
                                    maxLength={15}
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">

                            <div className="text-sm">
                                <a href="#" className="font-medium text-red-500 hover:text-red-700">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>

                        <div>
                            <button
                                type="button"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                                onClick={handleSubmission}
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <UserCircleIcon className="h-5 w-5 text-red-300 group-hover:text-red-400" aria-hidden="true" />
                                </span>
                                Sign in
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}