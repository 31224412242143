import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

export default function AboutAuthor() {

    const navigate = useNavigate();

    const about = "ගංගා ෂයිනි ගමගේ, පාඨක ජනාදරය දිනාගන්නේ නවකතාකාරිනියක් ලෙසිනි. මෙතෙක් ඇය විසින් ලියන ලද මුද්‍රිත නවකතා ගණන පණස් පණස් හතරකි. එමෙන්ම සයිබර් අවකාශ නවකතාකරණයට පිවිස, නවකතා විසි තුනක් මේ වෙත්දී රචනා කොට තිබේ. ප්‍රේමය හා ජීවිතය පාදක කොට ගත් නවකතා රචනයේ ලා වැඩි රුචිකත්වයක් දක්වන ඇය, ස්ත්‍රී - පුරුෂ සංකීර්ණ මිනිස් හැඟීම් හා චර්යාවන් හි බැඳීම් හා බිඳීම් ගැඹුරින් විමසන සරල ලේඛණ  ශෛලයකින් සිය පාඨක සමූහයාගේ ආදරය දිනා ගනිමින් සිටී.\n" +
        " \n" +
        "සඳරේණු යොවුන් පුවත් පත තුළින් නවකතාකරණයට අත් පොත් තැබූ ඇය, ඊට අමතරව ලංකාවේ ප්‍රධාන ධාරාවේ කාන්තා පුවත්පත් වන සිරිකත, තරුණි, රැජිණ වැනි පුවත්පත් සඳහාද නවකතා රචනයෙන් දායක වී තිබේ. නවකතා වලට අමතරව, කවි සහ පරිවර්තිත ලිපි රචනයෙහි ද ඇයට  ඇත්තේ සුවිශේෂී හැකියාවකි.\n" +
        " \n" +
        "ඇගේ නවකතා, මුද්‍රණ වාර කිහිපයකින් නැවත - නැවත පාඨකයන් අතරට ගෙන යෑමට අවශ්‍ය වන තරමට,  සිංහල නවකතා පාඨකාගාරය තුළ  සන්නාම ගතව ඇතැයි කීම අතිශයෝක්තියක් නොවේ.\n" +
        " \n" +
        "මීට අමතරව ඇගේ නවකතා කිහිපයක් ම ටෙලි නාට්‍යයට නැගී ඇති අතර, ජනාදරයට පත් දීර්ඝ ගුවන් විදුලි නාට්‍ය කිහිපයක් ම ඇය විසින් රචනා කරනු ලැබ තිබේ.\n" +
        " \n" +
        "සයිබර් අවකාශය ඔස්සේ  නවකතාකරණයට පිවිසීමෙන් නොනැවතී, මීළඟ ප්‍රවිශ්ඨය ලෙස ගංගා ෂයිනී ගමගේ e - book අත්දැකීම ඔස්සේ සිය පාඨකයන් අතරට මෙලෙස එක්ව සිටින්නී ය.";

    return (
        <div className="container mx-auto my-4 w-11/12 md:w-1/2 p-8 border border-gray-400">
            <Helmet>
                <title>About Author</title>
            </Helmet>
            <h1 className="text-bold text-2xl md:text-3xl text-red-500 mb-8">ගංගා ෂයිනි ගමගේ</h1>

            <img src={require("../images/author/author.jpg")} alt="Loading" className="w-32 h-32 md:w-48 md:h-48 float-left mr-4 mb-2 rounded-md" />
            <p className="whitespace-pre-wrap">{about}</p>

            <div className="content flex py-2">

            </div>

            <div className="text-red-500 mt-8">
                <h2 className="text-2xl">Links</h2>
                <ul>
                    <li className="hover:text-red-800">
                        <button onClick={() => { navigate("/terms-&-conditions") }}>Terms & Conditions</button>
                    </li>
                    <li className="hover:text-red-800">
                        <button onClick={() => { navigate("/privacy-policy") }}>Privacy Policy</button>
                    </li>
                </ul>
            </div>
        </div>
    )
}