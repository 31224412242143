import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import { Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import Background from './background';
import Home from '../home/home';
import AllNovels from '../novels/all-novels';
import ViewNovel from "../novels/view-novel";
import Bookshelf from "../novels/bookshelf";
import LandingPage from "../home/landing-page";
import DailyNovelEpisodes from "../daily-novel/daily-novel-episodes";
import DailyNovel from "../daily-novel/daily-novel";
import ShortStories from "../short-stories/short-stories";
import AllArticles from "../featured/all-articles";
import ShortStory from "../short-stories/short-story";
import Article from "../featured/article";
import Footer from "./footer";
import AboutAuthor from "../home/about-author";
import Confetti from 'react-confetti';
import {auth} from "../firebase-config";
import AuthLink from "./auth-link";

const navigation = [
    { name: 'Home', path: 'home' },
    { name: 'E-Books', path: 'all-novels' },
    { name: 'Bookshelf', path: 'bookshelf' },
    { name: 'About Author', path: 'about-author' }
]

export default function NavigationBar(props) {

    const navigate = useNavigate();
    const params = useParams();

    const user = auth.currentUser;

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(props.page);
    const [authDropDown, setAuthDropDown] = useState(false);

    const PageBody = (() => {
        if (currentPage === 'home') {
            return (<LandingPage />);
        }
        else if (currentPage === 'all-novels') {
            return (<AllNovels />);
        }
        else if (currentPage == 'bookshelf'){
            return (<Bookshelf/>);
        }
        else if (currentPage === 'ViewNovel'){
            return (<ViewNovel />);
        }
        else if (currentPage === 'daily-novel-episodes'){
            return (<DailyNovelEpisodes/>);
        }
        else if (currentPage === 'daily-novel'){
            return (<DailyNovel />);
        }
        else if (currentPage === 'short-stories'){
            return (<ShortStories/>);
        }
        else if (currentPage === 'short-story'){
            return (<ShortStory/>);
        }
        else if (currentPage === 'featured'){
            return (<AllArticles/>);
        }
        else if (currentPage === 'article'){
            return (<Article/>);
        }
        else if (currentPage === 'about-author'){
            return (<AboutAuthor/>);
        }
        return (<Home />);
    })

    useEffect(() => {
        setCurrentPage(props.page);
    });
    useEffect(() => {
        if (currentPage === 'Home') {
            console.log(currentPage);
        }
        console.log(currentPage);

    }, [currentPage, authDropDown]);

    return (
        <div className="isolate bg-white">
            <img className="hidden" src={require("../images/animation/sakura-petal.png")} id="sakura" width={2}/>
            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={30}
                colors={["#fcc9b9"]}
                gravity={0.025}
                initialVelocityY={5}
                initialVelocityX={5}
                wind={0.0025}
                // tweenDuration={2000}
                drawShape={ctx => {
                    ctx.drawImage(document.getElementById("sakura"), 0, 0, 20, 20);
                    ctx.stroke();
                    ctx.closePath();
                }}
            />
            <Background />
            <div className="isolate bg-transparent">
                <div className="px-6 py-6 lg:px-8">
                    <nav className="flex items-center justify-between" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img className="h-32" src={require("../images/logo.png")} alt="" />
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            {navigation.map((item) => (
                                <button key={item.name} onClick={() => { setCurrentPage(item.path); navigate("/home/" + item.path) }}
                                        href={item.path}
                                    className="text-xl font-semibold leading-6 text-red-900 hover:text-red-700 focus:text-red-400">
                                    {item.name}
                                </button>
                            ))}
                        </div>

                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                            <AuthLink/>
                        </div>
                    </nav>
                    <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-red-100/95 px-6 py-6 lg:hidden">
                            <div className="flex items-center justify-between">
                                <a href="#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Your Company</span>
                                    <div className="flex justify-center">
                                        <img className="w-1/2 mb-8" src={require("../images/logo.png")} alt="" />
                                    </div>
                                    <AuthLink/>
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) => (
                                            <button
                                                key={item.name}
                                                onClick={() => { setCurrentPage(item.path); navigate("/home/" + item.path); setMobileMenuOpen(false); }}
                                                className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 bg-white text-red-900 hover:text-red-700 hover:bg-gray-500/10 w-full"
                                            >
                                                {item.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </div>
                <div id="content" className="mb-24">
                    <PageBody />
                </div>
            </div>
            <Footer/>
        </div>
    )
}