import { useEffect, useState } from "react";
import ArticleService from "../services/arcticle-service";
import ArticleRow from "./article-row";
import { ArrowLeftCircleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export default function AllArticles() {

    const [articles, setArticles] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        ArticleService.getArticles().then((arr) => {
            arr.sort(sortByDate);
            setArticles(arr);
        })
    }, []);

    useEffect(() => {
        console.log(articles);
    }, [articles]);

    function searchArticles(e) {
        let key = e.target.value;
        ArticleService.searchArticles(key).then((arr) => {
            setArticles(arr);
        })
    }

    function sortByDate(a, b) {
        if (a.timestamp < b.timestamp) {
            return 1;
        }
        else if (a.timestamp > b.timestamp) {
            return -1;
        }
        return 0;
    }

    return (
        <div className="container md:w-2/3 mx-auto mt-8 px-4">

            <Helmet>
                <title>Featured</title>
            </Helmet>

            <div className="grid grid-cols-6">
                <div className="col-span-1">
                    <button className="text-xl font-semibold leading-6 text-red-900 hover:text-red-400 focus:text-red-300"
                        onClick={() => { navigate(-1) }}
                    >
                        <ArrowLeftCircleIcon className="h-10 w-10" />
                    </button>
                </div>
                <div className="col-span-5">
                    <h1 className="text-2xl text-red-700">Featured</h1>
                </div>
            </div>

            <div className="">
                <label className="sr-only">
                    Search
                </label>
                <input
                    id="search"
                    name="search"
                    type="text"
                    autoComplete="search"
                    required
                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 text-xl"
                    placeholder="Search"
                    onChange={searchArticles}
                />
            </div>



            {articles.map((article) => (
                <ArticleRow key={article.id} article={article} />
            ))}

        </div>
    );
}