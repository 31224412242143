import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";

class StorageService {

    constructor() {
        this.thumbnailRef = ref(storage, 'thumbnails');
        this.coverRef = ref(storage, 'covers');
        this.novelRef = ref(storage, 'novels');
    }

    uploadThumbnail(image) {
        const imageRef = ref(storage, '/thumbnails/' + image.name);
        uploadBytes(imageRef, image).then((snapshot) => {
            console.log(snapshot.ref);
            return snapshot.ref;
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    uploadCover(image) {
        const imageRef = ref(storage, '/covers/' + image.name);
        uploadBytes(imageRef, image).then((snapshot) => {
            console.log(snapshot.ref);
            return snapshot.ref;
        }).catch((err) => {
            console.log(err);
            return null;
        });
    }

    uploadNovel(file) {
        const pdfRef = ref(storage, '/novels/' + file.name);
        uploadBytes(pdfRef, file).then((snapshot) => {
            console.log(snapshot.ref);
            return snapshot;
        }).catch((err) => {
            console.log(err);
            return null;
        })
    }

    async getThumbnail(docId){
        const pathReference = ref(storage, '/thumbnails/thumbnail_' + docId + '.png' );
        const imageURL = await getDownloadURL(pathReference);
        return imageURL;
    }

    async getNovel(docId){
        const pathReference = ref(storage, '/novels/novel_' + docId + '.pdf');
        const novelURL = await getDownloadURL(pathReference);
        return novelURL;
    }

    async getNovelPreview(docId){
        const pathReference = ref(storage, '/novel_previews/novel_preview_' + docId + '.pdf');
        const novelURL = await getDownloadURL(pathReference);
        return novelURL;
    }

    async getShortStoryCover(docId){
        const pathReference = ref(storage, '/short_story_covers/short_story_cover_' + docId + '.png');
        const imageURL = await getDownloadURL(pathReference);
        return imageURL;
    }

    async getArticleCover(docId){
        const pathReference = ref(storage, '/article_covers/article_cover_' + docId + '.png');
        const imageURL = await getDownloadURL(pathReference);
        return imageURL;
    }

    async getDailyNovelCover(title){
        const pathReference = ref(storage, '/daily_novel_cover/'+ title +'.png');
        const imageURL = await getDownloadURL(pathReference);
        return imageURL;
    }
}

export default new StorageService();