import { collection, addDoc, query, where, getDoc, getDocs, doc } from 'firebase/firestore';
import { db } from '../firebase-config';

class ShortStoriesService {

    async getShortStories() {
        const shortStories = [];
        const querySnapshot = await getDocs(collection(db, "short-stories"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            shortStories.push({
                id: doc.id,
                title: doc.data().title,
                content: doc.data().content,
                timestamp: new Date(doc.data().timestamp).toLocaleString()
            });
        });
        return shortStories;
    }

    async getShortStory(id) {
        const storyDoc = await getDoc(doc(db, "short-stories", id));

        if(storyDoc.exists()){
            return {
                id: storyDoc.id,
                title: storyDoc.data().title,
                content: storyDoc.data().content,
                timestamp: new Date(storyDoc.data().timestamp).toLocaleString()
            };
        }
        else{
            return null;
        }
    }

    async searchShortStories(key) {
        const shortStories = [];
        const querySnapshot = await getDocs(collection(db, "short-stories"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if(doc.data().title.includes(key)){
                shortStories.push({
                    id: doc.id,
                    title: doc.data().title,
                    author: doc.data().author,
                    content: doc.data().content,
                    timestamp: new Date(doc.data().timestamp).toLocaleString()
                });
            }
        });
        return shortStories;
    }

}

export default new ShortStoriesService();