import NavigationBar from '../common/navbar';
import {useParams} from "react-router-dom";



export default function DailyNovelRoute() {

    const params = useParams();

    return (
        <div>
            <NavigationBar page="daily-novel" novelId={params.novelId}/>
        </div>
    );
}