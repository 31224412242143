// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBYGRhbsM_zht4tM8hCgpRGbhSfvsnstS4",
    authDomain: "gangashinienovels-cd1f5.firebaseapp.com",
    projectId: "gangashinienovels-cd1f5",
    storageBucket: "gangashinienovels-cd1f5.appspot.com",
    messagingSenderId: "1098501059895",
    appId: "1:1098501059895:web:9b4f9c4ea7cdaa308916b2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;