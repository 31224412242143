import { useEffect, useState } from "react";
import ShortStoryService from "../services/short-stories-service";
import NovelRow from "../novels/novel-row";
import ShortStoriesRow from "./short-stories-row";
import { ArrowLeftCircleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

export default function ShortStories() {

    const [shortStories, setShortStories] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        ShortStoryService.getShortStories().then((arr) => {
            setShortStories(arr);
        })
    }, []);

    useEffect(() => {
        console.log(shortStories);
    }, [shortStories]);

    function searchShortStories(e) {
        let key = e.target.value;
        ShortStoryService.searchShortStories(key).then((arr) => {
            setShortStories(arr);
        });
    }

    return (
        <div className="container md:w-2/3 mx-auto mt-8 px-4">

            <Helmet>
                <title>Short Stories</title>
            </Helmet>

            <div className="grid grid-cols-6">
                <div className="col-span-1">
                    <button className="text-xl font-semibold leading-6 text-red-900 hover:text-red-400 focus:text-red-300"
                        onClick={() => { navigate(-1) }}
                    >
                        <ArrowLeftCircleIcon className="h-10 w-10" />
                    </button>
                </div>
                <div className="col-span-5">
                    <h1 className="text-2xl text-red-700">Short Stories</h1>
                </div>
            </div>

            <div className="">
                <label className="sr-only">
                    Search
                </label>
                <input
                    id="search"
                    name="search"
                    type="text"
                    autoComplete="search"
                    required
                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 text-xl"
                    placeholder="Search"
                    onChange={searchShortStories}
                />
            </div>



            {shortStories.map((shortStory) => (
                <ShortStoriesRow key={shortStory.id} shortStory={shortStory} />
            ))}

        </div>
    );
}