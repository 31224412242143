import {useEffect, useState} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import StorageService from '../services/storage-service';
import {useNavigate, useParams} from "react-router-dom";
import {ArrowLeftOnRectangleIcon, ArrowLeftCircleIcon, ArrowRightCircleIcon, BookmarkIcon} from "@heroicons/react/20/solid";
import NovelService from "../services/novels-service";
import {auth} from "../firebase-config";
import { Helmet } from 'react-helmet-async';

export default function ReadPreview() {

    const params = useParams();

    const navigate = useNavigate();

    const [novelURL, setNovelURL] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const pageHeight = (window.innerWidth / 420) * 595;

    useEffect(() => {
        StorageService.getNovelPreview(params.novelId)
            .then((url) => {
                setNovelURL(url);
                console.log(novelURL);
            });

    },[novelURL]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function goToPage(num) {
        setPageNumber(num);
    }

    function setBookMark(){
        NovelService.addBookMark(auth.currentUser.uid, params.novelId, pageNumber)
            .then((ref) => {
                alert("Bookmark added!");
            })
    }

    return (
         <div className="grid h-screen place-items-center mt-4 w-10/12 md:w-full">
            <Helmet>
                <title>Novel Preview</title>
            </Helmet>
            <div className="grid grid-cols-8 md:grid-cols-12 gap-2">
                <div>
                    <button className="text-xl font-semibold leading-6 text-red-500 hover:text-red-400"
                            onClick={() => {navigate(-1)}}
                    >
                        <ArrowLeftOnRectangleIcon className="h-10 w-10"/>
                    </button>
                </div>
                <div></div>
                <div className="col-span-2 md:col-span-6">
                    <input type="number"
                           className="relative block w-full appearance-none rounded border border-gray-300 my-auto px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                           min={1}
                           max={numPages}
                           value={pageNumber}
                           onChange={(e) => goToPage(e.target.valueAsNumber)}
                    />
                </div>
                <div>
                    <button className="text-xl font-semibold leading-6 text-red-500 hover:text-red-400"
                            onClick={setBookMark}
                            disabled
                    >
                        <BookmarkIcon className="h-10 w-10"/>
                    </button>
                </div>
                <div></div>
                <div>
                    <button className="text-xl font-semibold leading-6 text-red-500 hover:text-red-400"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            >
                        <ArrowLeftCircleIcon className="h-10 w-10"/>
                    </button>
                </div>
                <div>
                    <button className="text-xl font-semibold leading-6 text-red-500 hover:text-red-400"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                    >
                        <ArrowRightCircleIcon className="h-10 w-10"/>
                    </button>
                </div>
            </div>
            <div className="flex mx-auto">
                <Document
                    file={novelURL}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} height={window.innerWidth >= 576 ? 1000 : pageHeight } />
                </Document>
            </div>
            <div>
                <h5 className="text-bold text-red-800">
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </h5>
            </div>
        </div>
    );
}