import {addDoc, collection} from "firebase/firestore";
import {db} from "../firebase-config";


class UserService{

    async addUser(user) {
        try {
            const docRef = await addDoc(collection(db, "users"), user);
            console.log("Document written with ID: ", docRef.id);
            return docRef.id;
        } catch (e) {
            console.error("Error adding document: ", e);
            return null;
        }
    }
}

export default new UserService();