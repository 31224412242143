import { useNavigate } from "react-router-dom";
import { HomeIcon, ArrowLeftCircleIcon, LockOpenIcon } from "@heroicons/react/24/solid";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function ResetPassword() {

    const navigate = useNavigate();

    const [email, setEmail] = useState(null);
    const [isEmailSent, setIsEmailSent] = useState(false);

    useEffect(() => {

    },[isEmailSent]);

    function handlePasswordReset() {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email).then((response) => {
            setIsEmailSent(true);
            console.log(response);
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <Helmet>
                    <title>Reset Password</title>
                </Helmet>
                <div className="w-full max-w-md space-y-8">
                    <div className="flex justify-center">
                        <button onClick={() => navigate(-1)}>
                            <ArrowLeftCircleIcon className="w-10 h-10 mx-12 text-red-400 hover:text-red-700" />
                        </button>
                        <button onClick={() => navigate("/")}>
                            <HomeIcon className="w-10 h-10 mx-12 text-red-400 hover:text-red-700" />
                        </button>
                    </div>
                    <div>
                        <img
                            className="mx-auto w-1/2"
                            src={require("../images/logo.png")}
                            alt="GanagaShinie Novels"
                        />
                        <h2 className="mt-6 text-center text-3xl tracking-tight text-gray-900">
                            Reset Password
                        </h2>
                    </div>

                    {!isEmailSent ?
                        <div className="mt-8 space-y-6">
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div className="-space-y-px rounded-md shadow-sm">
                                <div>
                                    <label htmlFor="email-address" className="sr-only">
                                        Email address
                                    </label>
                                    <input
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                        placeholder="Email address"
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                </div>

                            </div>

                            <div>
                                <button
                                    type="button"
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                                    onClick={handlePasswordReset}
                                >
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <LockOpenIcon className="h-5 w-5 text-red-300 group-hover:text-red-400" aria-hidden="true" />
                                    </span>
                                    Send Password Reset Link to my Email
                                </button>
                            </div>
                        </div>

                        :
                        <div className="mt-8 space-y-6">

                            <div className="flex justify-center bg-green-400 rounded-lg px-4 py-2 my-4">
                                <label className="text-white text-center">A password reset link has been sent to {email}. Please click on the link to reset your password.</label>
                            </div>

                            <div>
                                <button
                                    type="button"
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                                    onClick={() => navigate("/login")}
                                >
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <LockOpenIcon className="h-5 w-5 text-red-300 group-hover:text-red-400" aria-hidden="true" />
                                    </span>
                                    Go to Login
                                </button>
                            </div>

                        </div>


                    }
                </div>
            </div>
        </>
    );
}