import {useNavigate} from "react-router-dom";
import {auth} from "../firebase-config";
import PaymentService from "../services/payment-service";
import {useEffect, useState} from "react";
import {getAuth} from "firebase/auth";

export default function VerifyPayment(){

    const navigate = useNavigate();

    const [user, setUser] = useState(auth.currentUser);

    useEffect(() => {

        getAuth().onAuthStateChanged((u) => {
            setUser(u);

            PaymentService.getOrder(u.uid).then((ord) => {
                console.log(ord);
                let purchase = {
                    userId: u.uid,
                    novelId: ord.novelId,
                    firstName: ord.firstName,
                    lastName: ord.lastName,
                    email: ord.email,
                    contact: ord.contact,
                    address: ord.address,
                    amount: ord.amount,
                    timestamp: Date.now()
                }

                PaymentService.confirmPurchase(purchase).then((res) => {
                    navigate("/home/bookshelf");
                });
            });
        });

    },[])

    useEffect(() => {

    }, [user])


    return (
        <></>
    )
}