import NavigationBar from '../common/navbar';
import {useParams} from "react-router-dom";



export default function ShortStoryRoute() {

    const params = useParams();

    return (
        <div>
            <NavigationBar page="short-story" novelId={params.storyId}/>
        </div>
    );
}