import { useEffect, useState } from "react";
import NovelsService from "../services/novels-service";
import NovelRow from "./novel-row";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/20/solid";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

export default function Bookshelf() {

    const navigate = useNavigate();

    const [novels, setNovels] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(() => {

        getAuth().onAuthStateChanged((u) => {

            if (u) {
                setUser(u);
                NovelsService.getAuthorizedNovels(u.uid).then((arr) => {
                    setNovels(arr);
                });
                // NovelsService.getNovels().then((arr) => {
                //     arr.sort(sortByViews);
                //     const myList = [arr[0]];
                //     setNovels(myList);
                // });
            }
            else {
                navigate("/login");
            }

        });
    }, []);


    useEffect(() => {

    }, [user]);

    useEffect(() => {
        console.log(novels);
    }, [novels]);

    function sortByViews(a, b) {
        if (a.views < b.views) {
            return 1;
        }
        else if (a.views > b.views) {
            return -1;
        }
        return 0;
    }

    return (
        <div className="container md:w-2/3 mx-auto mt-8 px-4">
            <Helmet>
                <title>Bookshelf</title>
            </Helmet>
            <div className="">
                <label htmlFor="first-name" className="sr-only">
                    First name
                </label>
                <input
                    id="search"
                    name="search"
                    type="text"
                    autoComplete="search"
                    required
                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 text-xl"
                    placeholder="Search"
                />
            </div>

            <div className="container mx-auto my-4 py-8">
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 lg:gap-8">
                    {novels.map((novel) => (
                        <NovelRow key={novel} novelId={novel} isNew={false} />
                    ))}
                </div>
            </div>

        </div>
    );
}