import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './auth/login';
import Register from './auth/signup';
import ViewNovelRoute from "./novels/view-novel-route";
import Home from "./home/home";
import ReadPreview from "./novels/read-preview";
import DailyNovelRoute from "./daily-novel/daily-novel-route";
import ShortStoryRoute from "./short-stories/short-story-route";
import ArticleRoute from "./featured/article-route";
import FacebookAuth from "./auth/facebook-auth";
import BuyNovel from "./buy-novel/buy-novel";
import VerifyPayment from "./buy-novel/verify-payment";
import PrivacyPolicy from "./home/privacy-policy";
import Terms from "./home/terms";
import DataDeletion from "./home/data-deletion";
import ReadNovel from "./novels/read-novel";
import VerifyEmail from "./auth/verify-email";
import DeclinedPayment from "./buy-novel/declined-payment";
import { HelmetProvider } from 'react-helmet-async';
import ResetPassword from './auth/reset-password';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='verify-email' element={<VerifyEmail />} />
          <Route path='home' element={<Home />} />
          <Route path='home/:page' element={<Home />} />
          <Route path='view-novel/:novelId' element={<ViewNovelRoute />} />
          <Route path='read-preview/:novelId' element={<ReadPreview />} />
          <Route path='read-novel/:novelId' element={<ReadNovel />} />
          <Route path='daily-novel/:episodeId' element={<DailyNovelRoute />} />
          <Route path='short-story/:storyId' element={<ShortStoryRoute />} />
          <Route path='article/:articleId' element={<ArticleRoute />} />
          <Route path='buy-novel/:novelId' element={<BuyNovel />} />
          <Route path='verify-payment' element={<VerifyPayment />} />
          <Route path='declined-payment' element={<DeclinedPayment />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='terms-&-conditions' element={<Terms />} />
          <Route path='user-data-deletion' element={<DataDeletion />} />
          <Route path='reset-password' element={<ResetPassword/>}/>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
