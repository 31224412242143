import {useParams} from "react-router-dom";
import NavigationBar from "../common/navbar";

export default function ArticleRoute(){
    const params = useParams();

    return (
        <div>
            <NavigationBar page="article" articleId={params.articleId}/>
        </div>
    );
}