import { collection, addDoc, query, where, getDoc, getDocs, doc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase-config';

class PaymentService{

    async addOrder(order){
        try {
            const orderRef = doc(db, "orders", "order_" + order.userId);
            const bmDoc = await getDoc(orderRef);

            if(bmDoc.exists()){
                await updateDoc(orderRef, order);
            }
            else{
                await setDoc(orderRef, order);
            }
            return true;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }

    async getOrder(userId){
        const orderDoc = await getDoc(doc(db, "orders", "order_" + userId));

        if(orderDoc.exists()){
            return orderDoc.data();
        }
        else{
            return null;
        }
    }

    async confirmPurchase(purchase){
        try {
            const docRef = doc(db, "purchases", purchase.userId + purchase.novelId);
            await setDoc(docRef, purchase);
            return true;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }
}

export default new PaymentService();