import { collection, addDoc, query, where, getDoc, getDocs, doc, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase-config';

class DailyNovelService {

    async getEpisodes() {
        const episodes = []
        const querySnapshot = await getDocs(collection(db, "daily-novel"));
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let t = new Date(doc.data().timestamp);
            episodes.push({
                id: doc.id,
                title: doc.data().title,
                content: doc.data().content,
                episode: doc.data().episode,
                views: doc.data().views,
                timestamp: monthNames[t.getMonth()] + " " + t.getDate() + ", " + t.getFullYear()
            });
        });
        return episodes;
    }

    async getEpisode(id) {
        const episodeRef = collection(db, "daily-novel");
        const q = query(episodeRef, where("id", "==", id));
        const episodeDoc = await getDoc(doc(db, "daily-novel", id));

        if(episodeDoc.exists()){
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            let t = new Date(episodeDoc.data().timestamp);
            return {
                id: episodeDoc.id,
                title: episodeDoc.data().title,
                content: episodeDoc.data().content,
                episode: episodeDoc.data().episode,
                views: episodeDoc.data().views,
                timestamp: monthNames[t.getMonth()] + " " + t.getDate() + ", " + t.getFullYear()
            };
        }
        else{
            return null;
        }
    }

    async searchEpisodes(key) {
        const episodes = []
        const querySnapshot = await getDocs(collection(db, "daily-novel"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if(key == doc.data().episode){
                episodes.push({
                    id: doc.id,
                    title: doc.data().title,
                    content: doc.data().content,
                    episode: doc.data().episode,
                    views: doc.data().views,
                    timestamp: new Date(doc.data().timestamp).toLocaleString()
                });
            }
        });
        return episodes;
    }

    async addView(episode) {
        const view = {
            episode: episode,
            timestamp: Date.now()
        }
        try {
            const docRef = await addDoc(collection(db, "daily-novel-views"), view);
            console.log("Document written with ID: ", docRef.id);
            return docRef.id;
        } catch (e) {
            console.error("Error adding document: ", e);
            return null;
        }
    }

    async getViews(episode){
        const coll = collection(db, "daily-novel-views");
        const q = query(coll, where("episode", "==", episode));
        const snapshot = await getCountFromServer(q);
        console.log('count: ', snapshot.data().count);
        return snapshot.data().count;
    }
}

export default new DailyNovelService();