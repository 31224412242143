import NavigationBar from '../common/navbar';
import Background from '../common/background';
import {useParams} from "react-router-dom";



export default function ViewNovelRoute() {

    const params = useParams();

    return (
        // <NavigationBar page="ViewNovel" novelId={params.novelId}/>
        <div>
            <NavigationBar page="ViewNovel" novelId={params.novelId}/>
        </div>
    );
}