import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import StorageService from "../services/storage-service";
import {Base64} from "js-base64";

export default function ArticleRow(props){

    const navigate = useNavigate();

    const [image, setImage] = useState("");

    useEffect(() => {
        StorageService.getArticleCover(props.article.id)
            .then((url) => {
                setImage(url);
            });
    },[image]);

    return(
        <div className="border-solid border-2 border-red-200 hover:border-red-500 rounded-md my-2 p-2 max-h-44">
            <button onClick={() => navigate("/article/" + props.article.id)}>

                <div className="flex p-2">
                    <img src={image} className="max-h-32 rounded-md"/>
                    <div className="text-left ml-4 px-4 ">
                        <h3 className="text-bold text-red-800 sm:text-xl">{props.article.title} </h3>
                        <p className="text-xs sm:text-sm line-clamp-6 md:line-clamp-4">{Base64.decode(props.article.content)}</p>
                    </div>
                </div>

                {/*<div className="grid grid-cols-6 gap-4">*/}
                {/*    <div className="col-span-2 mx-auto">*/}
                {/*        <img src={image} className="w-48"/>*/}
                {/*    </div>*/}
                {/*    <div className="col-span-4 text-left px-4 ">*/}
                {/*        <h3 className="text-bold text-red-800 sm:text-lg">{props.article.title} </h3>*/}
                {/*        /!*<p className="text-xs sm:text-sm line-clamp-3">{atob(props.episode.content)}</p>*!/*/}
                {/*        <p className="text-xs sm:text-sm line-clamp-2 md:line-clamp-3">{Base64.decode(props.article.content)}</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </button>
        </div>

    );
}