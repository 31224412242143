
import {ArrowLeftCircleIcon} from "@heroicons/react/24/solid";
import {useNavigate} from "react-router-dom";
import Footer from "../common/footer";

export default function DataDeletion(){

    const navigate = useNavigate();

    const instructions = "GangaShineNovels app uses the Facebook login and we do not save your personal data in our server. As per Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL.\n" +
        "\n" +
        "If you want to delete your activities for GangaShineNovels app, you can remove your information by following these steps:\n" +
        "\n" +
        "1. Go to your Facebook Account's Setting & Privacy. Click Settings.\n" +
        "2. Look for Apps and Websites and you will see all of the apps and websites you linked with your Facebook.\n" +
        "3. Search and click GangaShineNovels in the search bar.\n" +
        "4. Scroll and click Remove.\n" +
        "5. Congratulations, you have successfully removed your app activities."

    return(
        <div className="container mx-auto my-4 w-11/12 md:w-1/2 p-8 border border-gray-400">


            <button className="text-red-500 hover:text-red-800" onClick={() => {navigate(-1)}}>
                <ArrowLeftCircleIcon className="h-10 w-10"/>
            </button>
            <h1 className="text-bold text-2xl md:text-3xl text-red-500 mb-8">User Data Deletion Instructions</h1>


            <p className="whitespace-pre-wrap">{instructions}</p>

            <Footer/>
        </div>
    )
}