import Home from "./home";
import {useNavigate} from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function LandingPage(){

    const navigate = useNavigate();

    return (
        <div className="container place-items-center md:w-2/3 mx-auto mt-8 md:mt-8 px-4">

            <Helmet>
                <title>GangaShinieNovels</title>
            </Helmet>

            <div>
                <button onClick={() => {navigate("/view-novel/pbvgI9Xn4xasfzfBDy5z")}} className="flex justify-center my-8">
                    <img src={require("../images/prema_gatha.jpg")} className="w-full md:w-1/2 rounded-md"/>
                </button>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">

                <div className="flex justify-center">
                    <button onClick={() => {navigate("/home/all-novels")}}>
                        <img src={require("../images/thumbnails/Ebooks.jpeg")} className="h-44 md:h-64 shadow-2xl hover:opacity-70 rounded-md"/>
                    </button>
                </div>

                <div className="flex justify-center">
                    <button onClick={() => {navigate("/home/daily-novel-episodes")}}>
                        <img src={require("../images/thumbnails/DailyNovel.jpeg")} className="h-44 md:h-64 shadow-2xl hover:opacity-70 rounded-md"/>
                    </button>
                </div>

                <div className="flex justify-center">
                    <button onClick={() => {navigate("/home/short-stories")}}>
                        <img src={require("../images/thumbnails/ShortStories.jpeg")} className="h-44 md:h-64 shadow-2xl hover:opacity-70 rounded-md"/>
                    </button>
                </div>

                <div className="flex justify-center">
                    <button onClick={() => {navigate("/home/featured")}}>
                        <img src={require("../images/thumbnails/Featured.jpeg")} className="h-44 md:h-64 shadow-2xl hover:opacity-70 rounded-md"/>
                    </button>
                </div>
            </div>
        </div>
    );

}