import {useEffect, useState} from "react";
import StorageService from "../services/storage-service";
import {NavLink, useNavigate} from "react-router-dom";
import SpecialNovel from "../home/special-novel";
import ViewNovel_1 from "./view-novel";
import {CheckBadgeIcon} from "@heroicons/react/24/solid";


export default function Thumbnail(props){

    const navigate = useNavigate();

    const [image, setImage] = useState("");
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        StorageService.getThumbnail(props.novelId)
            .then((url) => {
                setImage(url);
            });
    },[]);

    useEffect(() => {

    },[image]);

    return(
        <>
            { props.isNew ?
                <div className="border-2 border-yellow-300 rounded-md ">
                    <div className="flex justify-center px-4 pt-4 sm:pt-8">
                        <CheckBadgeIcon className="text-yellow-400 h-6 w-6"/>
                        <h1 className="text-bold text-red-800 ml-3">New Arrival</h1>
                    </div>
                    <button onClick={() => navigate("/view-novel/" + props.novelId)}>
                        <div className="flex justify-center">
                            {image ? <img src={image} className="max-h-64 sm:max-h-full w-3/4 hover:w-10/12 py-2 lg:py-2 hover:py-4"/> : ""}
                        </div>
                    </button>
                </div>
                :
                <div className="border-2 border-gray-300 rounded-md ">
                    <button onClick={() => navigate("/view-novel/" + props.novelId)}>
                        <div className="flex justify-center">
                            {image ? <img src={image} className="max-h-64 sm:max-h-full w-11/12 hover:w-full py-4 lg:py-8 hover:py-4"/> : ""}
                        </div>
                    </button>
                </div>
            }
        </>

    );
}