import { BookOpenIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import StorageService from "../services/storage-service";
import NovelsService from "../services/novels-service";
import { useNavigate, useParams } from "react-router-dom";
import Background from "../common/background";
import { ArrowLeftCircleIcon } from "@heroicons/react/20/solid";
import { Helmet } from "react-helmet-async";
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton, WhatsappIcon,
    WhatsappShareButton
} from "react-share";

export default function ViewNovel(props) {

    const navigate = useNavigate();
    const [image, setImage] = useState("");
    const [novel, setNovel] = useState(null);

    const params = useParams();
    const novelId = params.novelId;

    const currentURL = "https://gangashinienovels.lk" + window.location.pathname;

    useEffect(() => {
        StorageService.getThumbnail(novelId)
            .then((image) => {
                setImage(image);
                console.log(image);
            });

        NovelsService.getNovel(novelId)
            .then((novel) => {
                setNovel(novel);
            });
    }, []);

    useEffect(() => {

    }, [image]);

    return (
        <div className="container mx-auto my-8 px-8 border-3 border-black-500/75">

            {/*<button className="text-red-700" >Back</button>*/}
            <button className="text-xl font-semibold leading-6 text-red-900 hover:text-red-400 focus:text-red-300"
                onClick={() => { navigate(-1) }}
            >
                <ArrowLeftCircleIcon className="h-10 w-10" />
            </button>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex justify-center">
                    <img id="image" src={image} alt="Loading" className="w-6/12" />
                </div>
                <>
                    {novel && image ? (
                        <div className="container mx-auto p-4">

                            <Helmet>
                                <title>{novel.title}</title>
                            </Helmet>

                            <h1 className="font-bold-sans text-center text-gray-600 text-4xl">{novel.title}</h1>

                            <p className="my-4">
                                {novel.description}
                            </p>

                            <div className="grid grid-cols-2 gap-4">
                                <h2 className="text-red-600 text-bold my-4 text-2xl text-end mr-4">{novel.price}/- LKR</h2>
                                <h2 className="text-gray-600 text-bold my-4 text-2xl text-start ml-4">[ {(novel.price * 0.0031).toFixed(2)}/- $ ]</h2>
                            </div>

                            <div className="grid grid-cols-2 mt-10">
                                <button
                                    type="button"
                                    className="group relative mr-4 rounded-md border border-transparent bg-red-500 py-2 px-4 mx-auto text-lg-bold font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                                    onClick={() => { navigate("/read-preview/" + novel.id) }}
                                >
                                    Read Preview
                                </button>
                                <button
                                    type="button"
                                    className="group relative ml-4 rounded-md border border-transparent bg-red-500 py-2 px-4 mx-auto text-lg-bold font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                                    onClick={() => { navigate("/buy-novel/" + novel.id) }}
                                >
                                    Buy Now
                                </button>
                            </div>

                            <div className="container mx-auto mt-8">
                                <h2 className="text-red-500 text-xl text-center">Share</h2>
                                <div className="flex justify-center">
                                    <FacebookShareButton url={currentURL} hashtag="#gangashinienovels" quote="GangaShinieNovels">
                                        <FacebookIcon className="w-8 hover:w-10 mx-4" round="true" />
                                    </FacebookShareButton>
                                    <FacebookMessengerShareButton url={currentURL}>
                                        <FacebookMessengerIcon className="w-8 hover:w-10 mx-4" round="true" />
                                    </FacebookMessengerShareButton>
                                    <WhatsappShareButton url={currentURL}>
                                        <WhatsappIcon className="w-8 hover:w-10 mx-4" round="true" />
                                    </WhatsappShareButton>
                                </div>

                            </div>

                        </div>
                    ) : ""}
                </>
            </div>
        </div>
    );
}