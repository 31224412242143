import { useEffect, useState } from "react";
import DailyNovelService from '../services/daily-novel-service';
import EpisodeRow from "./episode-row";
import { ArrowLeftCircleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function DailyNovelEpisodes() {

    const [episodes, setEpisodes] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        DailyNovelService.getEpisodes().then((epi) => {
            epi.sort(sortByEpisode);
            setEpisodes(epi);
        })
    }, []);

    useEffect(() => {

    }, [episodes]);

    function searchEpisode(e) {
        let key = e.target.value;
        console.log("key : " + key.toString());
        if (key === "") {
            DailyNovelService.getEpisodes().then((epi) => {
                setEpisodes(epi);
            });
        }
        else {
            DailyNovelService.searchEpisodes(key).then((epis) => {
                console.log(epis.length);
                setEpisodes(epis);
            });
        }
    }

    function sortByEpisode(a, b) {
        if (a.episode < b.episode) {
            return 1;
        }
        else if (a.episode > b.episode) {
            return -1;
        }
        return 0;
    }

    return (
        <div className="container md:w-2/3 mx-auto mt-8 px-4">

            <Helmet>
                <title>Daily Dose of Novel</title>
            </Helmet>

            <div className="grid grid-cols-6">
                <div className="col-span-1">
                    <button className="text-xl font-semibold leading-6 text-red-900 hover:text-red-400 focus:text-red-300"
                        onClick={() => { navigate(-1) }}
                    >
                        <ArrowLeftCircleIcon className="h-10 w-10" />
                    </button>
                </div>
                <div className="col-span-5">
                    <h1 className="text-2xl text-red-700">Daily Dose of Novel</h1>
                </div>
            </div>

            <div className="">
                <label className="sr-only">
                    Search Episode
                </label>
                <input
                    id="search"
                    name="search"
                    type="number"
                    autoComplete="search"
                    required
                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 text-xl"
                    placeholder="Search Episode"
                    min={1}
                    max={episodes.length}
                    onChange={searchEpisode}
                />
            </div>
            {episodes.map((episode) => (
                <EpisodeRow episode={episode} key={episode.id} />
            ))}

        </div>
    )
}