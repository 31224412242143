import {useEffect, useState} from "react";
import StorageService from "../services/storage-service";
import {NavLink, useNavigate} from "react-router-dom";
import SpecialNovel from "../home/special-novel";
import ViewNovel_1 from "./view-novel";
import {CheckBadgeIcon} from "@heroicons/react/24/solid";


export default function NovelRow(props){

    const navigate = useNavigate();

    const [image, setImage] = useState("");
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        StorageService.getThumbnail(props.novelId)
            .then((url) => {
                setImage(url);
            });
    },[]);

    useEffect(() => {

    },[image]);

    return(
                <div className="border-2 border-gray-300 rounded-md ">
                    <button onClick={() => navigate("/read-novel/" + props.novelId)}>
                        <div className="flex justify-center">
                            {image ? <img src={image} className="max-h-64 sm:max-h-full w-11/12 hover:w-full py-4 lg:py-8 hover:py-4"/> : ""}
                        </div>
                    </button>
                </div>

    );
}