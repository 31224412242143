import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import NavigationBar from '../common/navbar';
import Background from '../common/background';
import {useNavigate, useParams} from "react-router-dom";


export default function Home() {

    const navigate = useNavigate();
    const params = useParams();

    if(!params.page){
        navigate("home/home");
    }

    return (
        <>
            {params.page ?
                <NavigationBar page={params.page}/> : <NavigationBar page='home'/>
            }
        </>


    );
}