import { MagnifyingGlassCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import NovelsService from "../services/novels-service";
import StorageService from "../services/storage-service";
import Thumbnail from "./thumbnail";
import { Helmet } from "react-helmet-async";

export default function AllNovels() {

    const [novels, setNovels] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        getAllNovels();
        setFilter("trending")
    }, []);

    useEffect(() => {
        console.log(novels);
    }, [novels, filter]);

    function getAllNovels() {
        NovelsService.getNovels().then((arr) => {
            arr.sort(sortByViews);
            setNovels(arr);
        });
    }

    function searchNovel(e) {
        let key = e.target.value;
        NovelsService.searchNovels(key).then((arr) => {
            setNovels(arr);
        });
    }

    function filterNovels(e) {
        setFilter(e.target.value);
        if (e.target.value === "byprice") {
            novels.sort(sortByPrice);
        }
        else if (e.target.value === "random") {
            novels.sort(shuffle);
        }
        else if (e.target.value === "trending") {
            novels.sort(sortByViews);
        }
        else if (e.target.value === "latest") {
            novels.sort(sortByDate);
        }
    }
    function sortByPrice(a, b) {
        if (a.price < b.price) {
            return -1;
        }
        else if (a.price > b.price) {
            return 1;
        }
        return 0;
    }

    function sortByDate(a, b) {
        if (a.timestamp < b.timestamp) {
            return 1;
        }
        else if (a.timestamp > b.timestamp) {
            return -1;
        }
        return 0;
    }

    function sortByViews(a, b) {
        if (a.views < b.views) {
            return 1;
        }
        else if (a.views > b.views) {
            return -1;
        }
        return 0;
    }

    function shuffle(a, b) {
        let randomNum = Math.floor(Math.random() * 100 + 1);
        if (randomNum % 2 == 0) {
            return -1
        }
        return 1;
    }

    return (
        <div className="container mx-auto my-4 px-8">

            <Helmet>
                <title>eBooks</title>
            </Helmet>

            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-10 md:col-span-7">
                    <label className="sr-only">
                        Search
                    </label>
                    <input
                        id="search"
                        name="search"
                        type="text"
                        autoComplete="search"
                        required
                        className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 text-xl"
                        placeholder="Search"
                        onChange={searchNovel}
                    />
                </div>
                <div><MagnifyingGlassCircleIcon className="h-16 text-red-300" /></div>
                <div className="col-end-13 md:col-span-4 col-span-12">
                    <label htmlFor="category" className="sr-only">
                        Category
                    </label>
                    <select
                        name="selectedFruit"
                        className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 text-xl"
                        value={filter}
                        onChange={filterNovels}
                    >
                        <option value="trending">Trending</option>
                        <option value="latest">Latest</option>
                        <option value="byprice">Price Ascending</option>
                        <option value="random">Random</option>
                    </select>
                </div>
            </div>

            <div className="container mx-auto my-4 py-8">
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 lg:gap-8">
                    <Thumbnail novelId="pbvgI9Xn4xasfzfBDy5z" isNew={true} />
                    {novels.map((novel) => (
                        <Thumbnail key={novel.id} novelId={novel.id} isNew={false} />
                    ))}
                </div>
            </div>

        </div>
    );
}