import { Base64 } from "js-base64";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ShortStoryService from "../services/short-stories-service";
import { ArrowLeftCircleIcon } from "@heroicons/react/20/solid";
import StorageService from "../services/storage-service";
import { Helmet } from 'react-helmet-async';
import { FacebookShareButton, FacebookMessengerShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, FacebookMessengerIcon, WhatsappIcon } from "react-share";

export default function ShortStory() {

    const navigate = useNavigate();

    const params = useParams();

    const [story, setStory] = useState(null);
    const [image, setImage] = useState(null);

    const currentURL = "https://gangashinienovels.lk" + window.location.pathname;

    useEffect(() => {
        ShortStoryService.getShortStory(params.storyId).then((str) => {
            setStory(str);
        });
        StorageService.getShortStoryCover(params.storyId).then((img) => {
            setImage(img);
        })
    }, [])

    useEffect(() => {

    }, [image])

    return (
        <div className="container mx-auto w-11/12 md:w-1/2">

            {story ?
                (<>
                    <Helmet>
                        <title>{story.title}</title>
                    </Helmet>
                    <div className="grid grid-cols-6">
                        <div className="col-span-1">
                            <button className="text-xl font-semibold leading-6 text-red-900 hover:text-red-400 focus:text-red-300"
                                onClick={() => { navigate(-1) }}
                            >
                                <ArrowLeftCircleIcon className="h-10 w-10" />
                            </button>
                        </div>
                        <div className="col-span-5">
                            <h1 className="text-xl text-red-700">Short Stories > {story.title}</h1>
                        </div>
                    </div>

                    <img src={image} alt={story.title} className="w-1/2 my-8 mx-4 float-left" />

                    <p className="whitespace-pre-wrap my-8">{Base64.decode(story.content)}</p>
                </>) : <h1>Loading..</h1>
            }

            <div className="container mx-auto mt-8">
                <h2 className="text-red-500 text-xl text-center">Share</h2>
                <div className="flex justify-center">
                    <FacebookShareButton url={currentURL} hashtag="#gangashinienovels">
                        <FacebookIcon className="w-8 hover:w-10 mx-4" round="true" />
                    </FacebookShareButton>
                    <FacebookMessengerShareButton url={currentURL}>
                        <FacebookMessengerIcon className="w-8 hover:w-10 mx-4" round="true" />
                    </FacebookMessengerShareButton>
                    <WhatsappShareButton url={currentURL}>
                        <WhatsappIcon className="w-8 hover:w-10 mx-4" round="true" />
                    </WhatsappShareButton>
                </div>

            </div>

        </div>
    );
}