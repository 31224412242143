import { collection, addDoc, query, where, getDoc, getDocs, doc } from 'firebase/firestore';
import { db } from '../firebase-config';

class ArticleService {

    async getArticles() {
        const articles = [];
        const querySnapshot = await getDocs(collection(db, "featured"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            articles.push({
                id: doc.id,
                title: doc.data().title,
                content: doc.data().content,
                timestamp: doc.data().timestamp
            });
        });
        return articles;
    }

    async getArticle(id) {

        const articleDoc = await getDoc(doc(db, "featured", id));

        if(articleDoc.exists()){
            return {
                id: articleDoc.id,
                title: articleDoc.data().title,
                content: articleDoc.data().content,
                timestamp: articleDoc.data().timestamp
            };
        }
        else{
            return null;
        }
    }

    async searchArticles(key) {
        const articles = [];
        const querySnapshot = await getDocs(collection(db, "featured"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if(doc.data().title.includes(key)){
                articles.push({
                    id: doc.id,
                    title: doc.data().title,
                    content: doc.data().content,
                    timestamp: new Date(doc.data().timestamp).toLocaleString()
                });
            }
        });
        return articles;
    }

}

export default new ArticleService();