import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Footer from "../common/footer";
import { Helmet } from 'react-helmet-async';

export default function Terms() {

    const navigate = useNavigate();

    const terms = "Thank you for using our book reading website. By using our website, you agree to these terms and conditions.\n" +
        "1.\tUser Content: You are solely responsible for any content you post on our website, including comments, reviews, and ratings. You must not post any content that is offensive, defamatory, or infringes on the rights of others. We reserve the right to remove any content that violates these terms.\n" +
        "2.\tCopyright: All content on our website, including text, images, and audio, is the property of our website or its content providers and is protected by copyright laws. You may not reproduce, distribute, or display any content without our prior written consent.\n" +
        "3.\tFacebook Authentication: By using Facebook authentication to sign up for our website, you agree to Facebook's terms and policies, as well as our own. We may access and use your Facebook profile information in accordance with our privacy policy.\n" +
        "4.\tLimitation of Liability: We are not liable for any damages arising from your use of our website, including but not limited to direct, indirect, incidental, or consequential damages. We do not guarantee the accuracy, completeness, or reliability of any content on our website.\n" +
        "5.\tIndemnification: You agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of our website or your violation of these terms.\n" +
        "6.\tModification: We reserve the right to modify these terms and conditions at any time without notice. Your continued use of our website after any changes indicates your acceptance of the modified terms.\n" +
        "7.\tGoverning Law: These terms and conditions are governed by the laws of Sri Lanka. Any disputes arising from your use of our website will be resolved in accordance with the laws of Sri Lanka.\n" +
        "8.\tSeverability: If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.\n" +
        "9.\tContact Us: If you have any questions or concerns about these terms and conditions, please contact us at gangashinienovels@gmail.com ."

    return (
        <div className="container mx-auto my-4 w-11/12 md:w-1/2 p-8 border border-gray-400">

            <Helmet>
                <title>Terms & Conditions</title>
            </Helmet>
            <button className="text-red-500 hover:text-red-800" onClick={() => { navigate(-1) }}>
                <ArrowLeftCircleIcon className="h-10 w-10" />
            </button>
            <h1 className="text-bold text-2xl md:text-3xl text-red-500 mb-8">Terms & Conditions</h1>


            <p className="whitespace-pre-wrap">{terms}</p>

            <Footer />
        </div>
    )
}