import {Fragment, useEffect, useState} from 'react'
import { Menu, Transition } from '@headlessui/react'
// import {auth} from "../firebase-config";
import {getAuth} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {UserCircleIcon} from "@heroicons/react/24/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AuthLink(props) {

    const [user, setUser] = useState(getAuth().currentUser);

    const navigate = useNavigate();

    useEffect(() => {
        getAuth().onAuthStateChanged((u) => {
            setUser(u);
        });
    },[])

    useEffect(() => {

    },[user]);

    function handleSignOut(){
        const auth = getAuth();
        auth.signOut().then((re) => {
            setUser(auth.currentUser);
        });
    }

    return (
        user ? <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 text-bold text-red-900 hover:text-red-600">
                            <div className="grid grid-cols-2 h-16 place-items-center">
                                <div>Hi {user.email}</div>
                                <div className="flex justify-center" >
                                    {/*<img src={user.photoURL} className="rounded-full"/>*/}
                                    <UserCircleIcon className="h-10 w-10 text-red-400 hover:text-red-700"/>
                                </div>
                            </div>
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                <form>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                onClick={handleSignOut}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block w-full px-4 py-2 text-left text-sm'
                                                )}
                                            >
                                                Sign out
                                            </button>
                                        )}
                                    </Menu.Item>
                                </form>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
                :
                <button
                    className="-mx-3 block py-2 px-3 text-base font-semibold leading-7 text-red-900 hover:text-red-600 w-full"
                    onClick={() => navigate("/login")}
                >
                    <div className="grid grid-cols-2 gap-8">
                        <div className="flex justify-end my-auto"><UserCircleIcon className="w-5 h-5"/></div>
                        <div className="flex justify-start"><label>Login</label></div>
                    </div>

                </button>

    )
}