import { CreditCardIcon, XCircleIcon } from '@heroicons/react/20/solid';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from "react";
import StorageService from "../services/storage-service";
import NovelsService from "../services/novels-service";
import {auth} from "../firebase-config";
import axios from "axios";
import PaymentService from "../services/payment-service";

import $ from "jquery";


export default function BuyNovel() {

    const navigate = useNavigate();

    const [image, setImage] = useState("");
    const [novel, setNovel] = useState(null);
    const [user, setUser] = useState(auth.currentUser);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("");
    const [address, setAddress] = useState("");
    const [amount, setAmount] = useState("");
    const [secretKey, setSecretKey] = useState('0e4fed42-ed79-45bb-b787-4950332f23c5');

    const params = useParams();
    const novelId = params.novelId;

    const client = axios.create({
        baseURL: "https://stagingxpay.info/index.php?route=checkout/billing"
    });

    useEffect(() => {
        if (!user){
            navigate("/login");
        }
        else{
            setEmail(user.email);
        }

        NovelsService.getNovel(novelId)
            .then((novel) => {
                setNovel(novel);
                setAmount(novel.price);
            });
    },[]);

    useEffect(() => {
        StorageService.getThumbnail(novelId)
            .then((image) => {
                setImage(image);
            });
    },[image, user, email]);


    function contactSubmit(e) {
        e.preventDefault();

        if (handleValidation()) {
            alert("Form submitted");
        } else {
            alert("Form has errors.")
        }
    }

    function handleValidation(){
        return true;
    }
    function payNow(e) {

        const NodeRSA = require('node-rsa');
        // const key = new NodeRSA('-----BEGIN PUBLIC KEY-----\n' +
        //     'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvLjaQKOblsZfepcOkvUwDBr0z\n' +
        //     'QqcCtsfD29RtYdJ8lL5Wj+kmF8OiIjXbQeSIgzmkdUejguTLS3PHqUjYb1ElFaLQ\n' +
        //     'CxW5oUWCwFSNYPUaVe+6wa/j6JfFTfsx4lvJHDIWeIBDrZMZ/wS6YTU+BnMvFhFj\n' +
        //     'JaT9Q/mwtnxHdD0dbQIDAQAB\n' +
        //     '-----END PUBLIC KEY-----');

        const key = new NodeRSA('-----BEGIN PUBLIC KEY-----\n' +
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDt2cwF6Y9qZ3JcuUStdcmRswIw\n' +
            'JdHAoZepy3W9cVnydVe+/M8wNvLYjo2V4CIg2olQnz22wr/pEWUs+flaty6AajMe\n' +
            'poX7dCDinxiLWqA9ZYKKPAfeal3KDbResJ6fJe3tC24YBSendBNomyeui9d9ceHW\n' +
            'e3uqfK3Ja4zCnXcXWQIDAQAB\n' +
            '-----END PUBLIC KEY-----');

        const min = 1;
        const max = 100;
        const rand = min + Math.random() * (max - min);


        let price = amount;
        let order_id = rand;
        let text = order_id + '|' + price;
        const encrypted = key.encrypt(text, 'base64');
        console.log(text);

        const order = {
            userId: user.uid,
            novelId: novelId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            contact: contact,
            address: address,
            amount: amount,
            timestamp: Date.now()
        }

        PaymentService.addOrder(order).then((res) => {
            if (handleValidation()) {
                $('.something').append('<form action="https://webxpay.com/index.php?route=checkout/billing" name="vote" id="vote" method="post" style="display:none;">' +
                    '<input type="text" name="first_name" value="' + firstName + '" />' +
                    '<input type="text" name="last_name" value="' + lastName + '" />' +
                    '<input type="text" name="email" value="' + email + '" />' +
                    '<input type="text" name="contact_number" value="' + contact + '" />' +
                    '<input type="text" name="address_line_one" value="' + address + '" />' +
                    '<input type="text" name="process_currency" value="' + 'LKR' + '" />' +
                    '<input type="text" name="secret_key" value="0e4fed42-ed79-45bb-b787-4950332f23c5" />' +
                    '<input type="text" name="order_id" value="' + user.uid + novelId + '" />' +
                    '<input type="text" name="payment" value="' + encrypted + '" />' +
                    '</form>');
                document.getElementById("vote").submit();
            } else {

            }
        });

        // client.post('', {
        //     first_name: firstName,
        //     last_name: lastName,
        //     email: email,
        //     contact_number: contact,
        //     address_line_one: address,
        //     process_currency: 'LKR',
        //     secret_key: '9072d2b4-5888-4eaf-b3a0-13ba15c8843b',
        //     custom_fields : '',
        //     payment: encrypted
        // })
        //     .then((response) => {
        //         console.log(response);
        //     });




    }


    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img
                            className="mx-auto h-24 w-auto"
                            src={require("../images/logo.png")}
                            alt="GanagaShinie Novels"
                        />

                        <div className="grid grid-cols-2 gap-4 my-8">
                            <div className="flex justify-center">
                                <img src={image} alt="Loading"/>
                            </div>
                            <div className="container mx-auto">
                                {novel ?
                                <>
                                    <h1 className="mt-6 text-center text-3xl tracking-tight text-gray-500">{novel.title}</h1>
                                    <h2 className="mt-6 text-center text-xl tracking-tight text-gray-500">{novel.author}</h2>
                                    <h2 className="mt-6 text-center text-xl tracking-tight text-red-500">LKR {novel.price} /-</h2>
                                </> : ""}
                            </div>
                        </div>

                    </div>
                    <div className="mt-8 space-y-6">
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="first-name" className="sr-only">
                                    First name
                                </label>
                                <input
                                    id="first-name"
                                    name="first-name"
                                    type="text"
                                    autoComplete="first-name"
                                    required
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={(e) => {setFirstName(e.target.value)}}
                                />
                            </div>
                            <div>
                                <label htmlFor="last-name" className="sr-only">
                                    Last name
                                </label>
                                <input
                                    id="last-name"
                                    name="last-name"
                                    type="text"
                                    autoComplete="last-name"
                                    required
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={(e) => {setLastName(e.target.value)}}
                                />
                            </div>

                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    Email
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Email"
                                    value={email}
                                    disabled
                                />
                            </div>

                            <div>
                                <label htmlFor="telephone" className="sr-only">
                                    Contact
                                </label>
                                <input
                                    id="contact"
                                    name="contact"
                                    type="tel"
                                    autoComplete="contact"
                                    required
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Contact"
                                    value={contact}
                                    onChange={(e) => {setContact(e.target.value)}}
                                />
                            </div>

                            <div>
                                <label htmlFor="address" className="sr-only">
                                    Address
                                </label>
                                <input
                                    id="address"
                                    name="address"
                                    type="text"
                                    autoComplete="address"
                                    required
                                    className="relative block w-full appearance-none rounded border border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Address"
                                    value={address}
                                    onChange={(e) => {setAddress(e.target.value)}}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-4">
                            <button
                                type="button"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                                onClick={() => {navigate(-1)}}
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <XCircleIcon className="h-5 w-5 text-red-300 group-hover:text-red-400" aria-hidden="true" />
                                </span>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="group relative flex col-span-2 w-full justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                                onClick={payNow}
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <CreditCardIcon className="h-5 w-5 text-red-300 group-hover:text-red-400" aria-hidden="true" />
                                </span>
                                Pay Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="something"></div>
        </>
    )
}