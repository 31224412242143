import { ArrowLeftCircleIcon, HomeIcon } from "@heroicons/react/24/solid";
import { NavLink, useNavigate } from "react-router-dom";
import { BookOpenIcon } from "@heroicons/react/20/solid";
import { Helmet } from 'react-helmet-async';

export default function DeclinedPayment() {

    const navigate = useNavigate();

    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">

                <Helmet>
                    <title>Unsuccessful Payment</title>
                </Helmet>
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img
                            className="mx-auto w-1/2"
                            src={require("../images/logo.png")}
                            alt="GanagaShinie Novels"
                        />
                    </div>

                    <div className="flex justify-center bg-orange-300 rounded-lg px-4 py-2 my-4">
                        <label className="text-white text-center">Your payment was unsuccessful. Please try again with a valid payment method.</label>
                    </div>


                    <div>
                        <button
                            type="submit"
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                            onClick={() => {
                                navigate("/home/all-novels")
                            }}
                        >
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <BookOpenIcon className="h-5 w-5 text-red-300 group-hover:text-red-400" aria-hidden="true" />
                            </span>
                            Continue to Book Store
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}